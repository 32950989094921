import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const userLanguage = async () => {
  //await refreshToken()
  const response = await api
    .get({
      path: 'ref/specializedsub',
    })
    .then(res => (res.data ? res.data : []))
    .catch(err => {
      console.log('get specializedsub err : ', err)

      return []
    })

  return response
}
export default userLanguage
