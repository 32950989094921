import { api } from '../main'
import refreshToken from '@/plugins/refresh-token'

const sectionday = async () => {
  //await refreshToken()
  const response = await api
    .get({
      path: 'ref/sectionday',
    })
    .then(res => (res.data ? res.data : []))
    .catch(err => {
      console.log('get section profile err : ', err)

      return []
    })

  return response
}
export default sectionday
