<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab>
        <v-icon
          size="20"
          class="me-3"
        >
          {{ icons.mdiAccountOutline }}
        </v-icon>
        <span>{{ $t('general_information') }}</span>
      </v-tab>
      <v-tab>
        <v-icon
          size="20"
          class="me-3"
        >
          {{ icons.mdiLockOpenOutline }}
        </v-icon>
        <span>{{ $t('password') }}</span>
      </v-tab>
      <v-tab>
        <v-icon
          size="20"
          class="me-3"
        >
          {{ icons.mdiKeyChain }}
        </v-icon>
        <span>{{ $t('password_confirm') }}</span>
      </v-tab>
      <v-tab>
        <v-icon
          size="20"
          class="me-3"
        >
          {{ icons.mdiContentSaveEditOutline }}
        </v-icon>
        <span>{{ $t('record_doctor_information') }}</span>
      </v-tab>
      <!-- <v-tab>
        <v-icon
          size="20"
          class="me-3"
        >
          {{ icons.mdiFile }}
        </v-icon>
        <span>{{ $t('formatReceipt') }}</span>
      </v-tab> -->
      <v-tab>
        <v-icon
          size="20"
          class="me-3"
        >
          {{ icons.mdiCalendar }}
        </v-icon>
        <span>{{ $t('time') }} - {{ $t('show_appointments') }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <account-settings-account
          :account-data="accountSettingData"
          :loading="loading"
          @update="getProfile"
        ></account-settings-account>
      </v-tab-item>

      <v-tab-item>
        <account-settings-password @update="getProfile"></account-settings-password>
      </v-tab-item>

      <v-tab-item>
        <account-settings-password-confirm @update="getProfile"></account-settings-password-confirm>
      </v-tab-item>

      <v-tab-item>
        <account-settings-doctor-data
          :account-data="accountSettingData"
          :loading="loading"
          @update="getProfile"
        ></account-settings-doctor-data>
      </v-tab-item>

      <!-- <v-tab-item>
        <account-settings-order
          :account-data="accountSettingData"
          :loading="loading"
          @update="getProfile"
        ></account-settings-order>
      </v-tab-item> -->
      <v-tab-item>
        <account-settings-time
          :day="accountSettingData.sectionday||[]"
          :time="accountSettingData.section||[]"
        ></account-settings-time>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import {
  mdiAccountOutline,
  mdiBellOutline,
  mdiBookmarkOutline,
  mdiCalendar,
  mdiContentSaveEditOutline,
  mdiFile,
  mdiInformationOutline,
  mdiKeyChain,
  mdiLockOpenOutline,
} from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import profile from '@/api/profile'
import { i18n } from '@/plugins/i18n'
import store from '@/store'

// demos
import AccountSettingsAccount from './AccountSettingsAccount.vue'
import AccountSettingsDoctorData from './AccountSettingsDoctorData.vue'
import AccountSettingsOrder from './AccountSettingsOrder.vue'
import AccountSettingsPassword from './AccountSettingsPassword.vue'
import AccountSettingsPasswordConfirm from './AccountSettingsPasswordConfirm.vue'
import AccountSettingsTime from './AccountSettingsTime.vue'

export default {
  components: {
    AccountSettingsAccount,
    AccountSettingsPasswordConfirm,
    AccountSettingsPassword,
    AccountSettingsOrder,
    AccountSettingsDoctorData,
    AccountSettingsTime,
  },
  setup() {
    const tab = ref('')
    const loading = ref(true)

    const tabs = [
      { title: i18n.t('general_information'), icon: mdiAccountOutline },
      { title: i18n.t('password'), icon: mdiLockOpenOutline },
      { title: i18n.t('password_confirm'), icon: mdiKeyChain },
      { title: i18n.t('record_doctor_information'), icon: mdiContentSaveEditOutline },
      { title: i18n.t('formatReceipt'), icon: mdiFile },
      { title: `${i18n.t('time')} - ${i18n.t('show_appointments')}`, icon: mdiCalendar },
    ]
    onMounted(() => {
      getProfile()
    })

    const accountSettingData = ref({})
    const getProfile = () => {
      store.commit('app/setStatusUpdateHeader')
      loading.value = true
      profile().then(res => {
        accountSettingData.value = res
        loading.value = false
      })
    }

    return {
      getProfile,
      loading,
      tab,
      tabs,
      accountSettingData,
      icons: {
        mdiFile,
        mdiKeyChain,
        mdiContentSaveEditOutline,
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiBookmarkOutline,
        mdiBellOutline,
        mdiCalendar,
      },
    }
  },
}
</script>
