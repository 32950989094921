<template>
  <div>
    <v-card
      flat
      class="mt-5 px-5"
    >
      <v-row>
        <v-col
          cols="12"
          lg="6"
        >
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-uppercase">
                    {{ $t('day') }}
                  </th>
                  <th class="text-center text-uppercase">
                    {{ $t('status') }}
                  </th>
                  <th>
                    {{ $t('option') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in mapDays"
                  :key="item.sectionday_id"
                >
                  <td>{{ item.sectionday_name_th }}</td>
                  <td class="text-center">
                    <v-chip
                      small
                      :class="item.status == 1 ? 'v-chip-light-bg primary--text' : 'v-chip-light-bg error--text'"
                    >
                      <v-icon
                        small
                        left
                        :color="item.status == 1 ? 'primary' : 'error'"
                      >
                        {{ item.status == 1 ? icons.mdiCheck : icons.mdiClose }}
                      </v-icon>
                      {{ item.status == 1 ? $t('open') : $t('close') }}
                    </v-chip>
                  </td>
                  <td>
                    <v-switch
                      v-model="item.status"
                      :true-value="1"
                      :false-value="2"
                      hide-details
                      @change="udpateStatusDay(item.sectionday_id, item.status)"
                    >
                    </v-switch>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="mt-5 d-none d-lg-flex justify-center position-relative">
            <v-img
              contain
              src="@/assets/images/3d-characters/profile/clock.png"
              max-width="350"
            >
            </v-img>
          </div>
        </v-col>
        <v-col
          cols="12"
          lg="6"
        >
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-uppercase">
                    {{ $t('day') }}
                  </th>
                  <th class="text-center text-uppercase">
                    {{ $t('status') }}
                  </th>
                  <th>
                    {{ $t('option') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in dataMapUserTimeList"
                  :key="item.section_id"
                >
                  <td>{{ item.section_time }}</td>
                  <td class="text-center">
                    <v-chip
                      small
                      :class="item.status == 1 ? 'v-chip-light-bg primary--text' : 'v-chip-light-bg error--text'"
                    >
                      <v-icon
                        small
                        left
                        :color="item.status == 1 ? 'primary' : 'error'"
                      >
                        {{ item.status == 1 ? icons.mdiCheck : icons.mdiClose }}
                      </v-icon>
                      {{ item.status == 1 ? $t('open') : $t('close') }}
                    </v-chip>
                  </td>
                  <td>
                    <v-switch
                      v-model="item.status"
                      :true-value="1"
                      :false-value="2"
                      hide-details
                      @change="updateStatustime(item.section_id, item.status)"
                    >
                    </v-switch>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import {
  mdiChartTimelineVariant, mdiCheck, mdiClose, mdiEmailOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import profile from '@/api/profile/profile'
import section from '@/api/profile/section'
import sectionday from '@/api/profile/sectionday'
import store from '@/store'

export default {
  props: {
    time: {
      type: Array,
      default: () => [],
    },
    day: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const userTimeList = ref(props.time)
    const dayList = ref(props.day)
    const days = ref([])
    const times = ref([])
    const mapDays = ref([])
    const dataMapUserTimeList = ref([])
    const {
      openDay, closeDay, openTime, closeTime,
    } = profile

    sectionday().then(res => {
      days.value = res
      section().then(res => {
        times.value = res
        mapDay()
        mapTime()
      })
    })
    const mapDay = () => {
      const arr = days.value.map(item => {
        const status = dayList.value.find(item2 => item.sectionday_id == item2.sectionday_id)

        return { ...item, status: status ? 1 : 2 }
      })
      mapDays.value = arr
    }
    const mapTime = () => {
      const arr = times.value.map(item => {
        const status = userTimeList.value.find(item2 => item.section_id == item2.section_id)

        return { ...item, status: status ? 1 : 2 }
      })
      dataMapUserTimeList.value = arr
    }
    const udpateStatusDay = (id, status) => {
      if (status == 1) {
        addDay(id)
      } else {
        deleteDay(id)
      }
    }
    const addDay = id => {
      const body = {
        sectionday_id: id,
      }
      openDay(body).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
      })
    }
    const deleteDay = id => {
      closeDay(id).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
      })
    }
    const updateStatustime = (id, status) => {
      if (status == 1) {
        addTime(id)
      } else {
        deleteTime(id)
      }
    }
    const addTime = id => {
      const body = {
        section_id: id,
      }
      openTime(body).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
      })
    }
    const deleteTime = id => {
      closeTime(id).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
      })
    }

    return {
      dataMapUserTimeList,
      mapDays,
      udpateStatusDay,
      updateStatustime,

      icons: {
        mdiChartTimelineVariant,
        mdiEmailOutline,
        mdiCheck,
        mdiClose,
      },
    }
  },
}
</script>
