<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        lg="7"
        order="2"
        order-lg="1"
      >
        <v-card flat>
          <v-row>
            <v-col cols="6">
              <v-card-title>
                <v-checkbox
                  v-model="format1"
                  @change="changeFormat1"
                >
                </v-checkbox>
                <span>{{ $t('format1') }}</span>
              </v-card-title>
              <v-card-text>
                <v-img
                  class="elevation-3 rounded-lg"
                  contain
                  src="@/assets/images/3d-characters/profile/ui-receipt/ui-1.png"
                >
                </v-img>
              </v-card-text>
            </v-col>
            <v-col cols="6">
              <v-card-title>
                <v-checkbox
                  v-model="format2"
                  @change="changeFormat2"
                >
                </v-checkbox>
                <span>{{ $t('format2') }}</span>
              </v-card-title>
              <v-card-text>
                <v-img
                  contain
                  class="elevation-3 rounded-lg"
                  src="@/assets/images/3d-characters/profile/ui-receipt/ui-2.png"
                >
                </v-img>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        lg="5"
        order="1"
        order-lg="2"
      >
        <v-card
          flat
          class="mt-5"
        >
          <v-row>
            <v-col cols="12">
              <v-card-title>
                {{ $t('CurrentFormat') }}
              </v-card-title>
              <v-card-text>
                <v-img
                  v-if="accountData.user_order_ui == '2'"
                  contain
                  class="elevation-3 rounded-lg"
                  src="@/assets/images/3d-characters/profile/ui-receipt/ui-2.png"
                >
                </v-img>
                <v-img
                  v-else
                  contain
                  class="elevation-3 rounded-lg"
                  src="@/assets/images/3d-characters/profile/ui-receipt/ui-1.png"
                >
                </v-img>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        order="3"
        order-lg="3"
      >
        <v-card-text>
          <v-btn
            color="primary"
            :loading="loadingupdate"
            :disabled="loadingupdate"
            class="me-3 mt-5"
            @click="updateOrderUi"
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-text>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiChartTimelineVariant, mdiEmailOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import profile from '@/api/profile/profile'
import store from '@/store'

export default {
  props: {
    accountData: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const loadingupdate = ref(false)
    const format1 = ref(false)
    const format2 = ref(false)
    const { updateProfile } = profile

    if (props.accountData.user_order_ui == '1') {
      format1.value = true
      format2.value = false
    } else {
      format1.value = false
      format2.value = true
    }
    const optionsLocale = ref({})

    const changeFormat1 = () => {
      format2.value = !format2.value
    }
    const changeFormat2 = () => {
      format1.value = !format1.value
    }

    const updateOrderUi = () => {
      loadingupdate.value = true
      props.accountData.user_order_ui = format1.value ? '1' : '2'
      updateProfile(props.accountData).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loadingupdate.value = false
        context.emit('update', res.message)
      })
    }

    return {
      changeFormat1,
      changeFormat2,
      updateOrderUi,
      format1,
      format2,
      loadingupdate,
      optionsLocale,
      icons: { mdiChartTimelineVariant, mdiEmailOutline },
    }
  },
}
</script>
