<template>
  <div>
    <v-card
      flat
      class="pa-3"
    >
      <v-card-text>
        <v-form class="multi-col-validation">
          <v-row>
            <v-col
              cols="12"
              lg="8"
            >
              <v-row
                justify="center"
                align="center"
              >
                <v-col cols="12">
                  <h3>
                    <v-icon left>
                      {{ icons.mdiBriefcaseOutline }}
                    </v-icon>{{ $t('the_work') }}
                  </h3>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="user_language_id"
                    :items="languages"
                    item-text="user_language_name"
                    chips
                    small-chips
                    deletable-chips
                    item-value="user_language_id"
                    :label="$t('language')"
                    :no-data-text="$t('no_data')"
                    multiple
                    dense
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="specialized_id"
                    :items="specializeds"
                    item-text="specialized_name"
                    item-value="specialized_id"
                    :label="$t('specialized_branches')"
                    :no-data-text="$t('no_data')"
                    dense
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col md="12">
                  <v-autocomplete
                    v-model="specialized_sub_id"
                    :items="specializedsubs"
                    item-text="specialized_sub_name"
                    multiple
                    chips
                    deletable-chips
                    small-chips
                    item-value="specialized_sub_id"
                    :label="$t('specialized_sub_branch')"
                    :no-data-text="$t('no_data')"
                    dense
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <h3>
                    <v-icon left>
                      {{ icons.mdiSchool }}
                    </v-icon>{{ $t('education') }}
                  </h3>
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <div class="d-flex align-center">
                    <h4>
                      <v-icon
                        left
                        x-small
                      >
                        {{ icons.mdiCircle }}
                      </v-icon>{{ $t('education_thai') }}
                    </h4>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="me-5"
                      color="primary"
                      fab
                      x-small
                      @click="addEduction(1)"
                    >
                      <v-icon>{{ icons.mdiPlus }}</v-icon>
                    </v-btn>
                  </div>
                  <v-list>
                    <v-list-item
                      v-for="item in educationThList"
                      :key="item.user_study_th_id"
                    >
                      <v-list-item-content>
                        <v-list-item-title>- {{ item.user_study_th_name }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action style="flex-direction: unset">
                        <v-btn
                          small
                          icon
                          color="primary"
                          @click="editEducation(item.user_study_th_id, item.user_study_th_name, 1)"
                        >
                          <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                        </v-btn>
                        <v-btn
                          small
                          icon
                          color="error"
                          @click="removeEducation(item.user_study_th_id, 1)"
                        >
                          <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <div class="d-flex align-center">
                    <h4>
                      <v-icon
                        left
                        x-small
                      >
                        {{ icons.mdiCircle }}
                      </v-icon>{{ $t('education_en') }}
                    </h4>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="me-5"
                      color="primary"
                      fab
                      x-small
                      @click="addEduction(2)"
                    >
                      <v-icon>{{ icons.mdiPlus }}</v-icon>
                    </v-btn>
                  </div>
                  <v-list>
                    <v-list-item
                      v-for="item in educationEnList"
                      :key="item.user_study_en_id"
                    >
                      <v-list-item-content>
                        <v-list-item-title>- {{ item.user_study_en_name }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action style="flex-direction: unset">
                        <v-btn
                          small
                          icon
                          color="primary"
                          @click="editEducation(item.user_study_en_id, item.user_study_en_name, 2)"
                        >
                          <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                        </v-btn>
                        <v-btn
                          small
                          icon
                          color="error"
                          @click="removeEducation(item.user_study_en_id, 2)"
                        >
                          <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <div class="d-flex align-center">
                    <h4>
                      <v-icon
                        left
                        x-small
                      >
                        {{ icons.mdiCircle }}
                      </v-icon>{{ $t('certificate_name_thai') }}
                    </h4>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="me-5"
                      color="primary"
                      fab
                      x-small
                      @click="addEduction(3)"
                    >
                      <v-icon>{{ icons.mdiPlus }}</v-icon>
                    </v-btn>
                  </div>
                  <v-list>
                    <v-list-item
                      v-for="item in certThList"
                      :key="item.user_diploma_th_id"
                    >
                      <v-list-item-content>
                        <v-list-item-title>- {{ item.user_diploma_th_name }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action style="flex-direction: unset">
                        <v-btn
                          small
                          icon
                          color="primary"
                          @click="editEducation(item.user_diploma_th_id, item.user_diploma_th_name, 3)"
                        >
                          <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                        </v-btn>
                        <v-btn
                          small
                          icon
                          color="error"
                          @click="removeEducation(item.user_diploma_th_id, 3)"
                        >
                          <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <div class="d-flex align-center">
                    <h4>
                      <v-icon
                        left
                        x-small
                      >
                        {{ icons.mdiCircle }}
                      </v-icon>{{ $t('certificate_name_en') }}
                    </h4>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="me-5"
                      color="primary"
                      fab
                      x-small
                      @click="addEduction(4)"
                    >
                      <v-icon>{{ icons.mdiPlus }}</v-icon>
                    </v-btn>
                  </div>
                  <v-list>
                    <v-list-item
                      v-for="item in certEnList"
                      :key="item.user_diploma_en_id"
                    >
                      <v-list-item-content>
                        <v-list-item-title>- {{ item.user_diploma_en_name }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action style="flex-direction: unset">
                        <v-btn
                          small
                          icon
                          color="primary"
                          @click="editEducation(item.user_diploma_en_id, item.user_diploma_en_name, 4)"
                        >
                          <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                        </v-btn>
                        <v-btn
                          small
                          icon
                          color="error"
                          @click="removeEducation(item.user_diploma_en_id, 4)"
                        >
                          <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-col>

            <v-col
              cols="12"
              lg="4"
              class="d-none d-lg-flex justify-center position-relative "
            >
              <v-img
                contain
                src="@/assets/images/3d-characters/profile/YY2.png"
                class="security-character"
              >
              </v-img>
            </v-col>

            <v-col cols="12">
              <v-btn
                color="primary"
                :loading="loadingUpdate"
                :disabled="loadingUpdate"
                class="me-3 mt-3"
                @click="editBasicDoctorData"
              >
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="dialogAddEducation"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title>
          <span v-if="statusAddEducation == 1">{{ $t('education_thai') }}</span>
          <span v-if="statusAddEducation == 2">{{ $t('education_en') }}</span>
          <span v-if="statusAddEducation == 3">{{ $t('certificate_name_thai') }}</span>
          <span v-if="statusAddEducation == 4">{{ $t('certificate_name_en') }}</span>
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="nameEducation"
            dense
            outlined
            :label="title"
            hide-details
            autofocus
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading="processLoading"
            :disabled="processLoading || !nameEducation"
            color="primary"
            @click="statusAddOrEdit == 1 ? addEductionData() : updateEducationData()"
          >
            {{ $t('save') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="dialogAddEducation = false"
          >
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiBriefcaseOutline, mdiCircle, mdiDeleteOutline, mdiPencilOutline, mdiPlus, mdiSchool,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import profile from '@/api/profile/profile'
import specialized from '@/api/profile/specialized'
import specializedsub from '@/api/profile/specializedsub'
import userLanguage from '@/api/profile/userLanguage'
import { i18n } from '@/plugins/i18n'
import store from '@/store'

export default {
  props: {
    accountData: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const processLoading = ref(false)
    const user_language_id = ref([])
    const specialized_sub_id = ref([])
    const languages = ref([])
    const specializeds = ref([])
    const specializedsubs = ref([])
    const specialized_id = ref(props.accountData.specialized_id)
    const educationThList = ref(props.accountData.user_study_th)
    const educationEnList = ref(props.accountData.user_study_en)
    const certThList = ref(props.accountData.user_diploma_th)
    const certEnList = ref(props.accountData.user_diploma_en)
    const title = ref('')
    const loadingUpdate = ref(false)
    const idEducation = ref('')
    const statusEdit = ref('')
    const dialogAddEducation = ref(false)
    const statusAddOrEdit = ref(1)
    const statusAddEducation = ref(1)
    const {
      updateBasicDoctorData, insertEducation, updateEducation, deleteEducation,
    } = profile
    const nameEducation = ref('')
    watch(
      () => props.loading,
      () => {
        specialized_id.value = props.accountData.specialized_id
        educationThList.value = props.accountData.user_study_th
        educationEnList.value = props.accountData.user_study_en
        certThList.value = props.accountData.user_diploma_th
        certEnList.value = props.accountData.user_diploma_en
      },
    )
    const editBasicDoctorData = () => {
      loadingUpdate.value = true
      const body = {
        specialized_id: specialized_id.value,
        specialized_sub_id: specialized_sub_id.value,
        user_language_id: user_language_id.value,
      }
      updateBasicDoctorData(body).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loadingUpdate.value = false
        emit('update', res.message)
      })
    }

    const addEduction = status => {
      statusAddOrEdit.value = 1
      nameEducation.value = ''
      dialogAddEducation.value = true
      statusAddEducation.value = status
      if (status == 1) {
        title.value = i18n.t('education_thai')
      } else if (status == 2) {
        title.value = i18n.t('education_en')
      } else if (status == 3) {
        title.value = i18n.t('certificate_name_thai')
      } else if (status == 4) {
        title.value = i18n.t('certificate_name_en')
      }
    }

    const addEductionData = () => {
      processLoading.value = true
      let body = {}
      let path = ''
      if (statusAddEducation.value == 1) {
        path = 'studyth'
        body = {
          user_study_th_name: nameEducation.value,
        }
      } else if (statusAddEducation.value == 2) {
        path = 'studyen'
        body = {
          user_study_en_name: nameEducation.value,
        }
      } else if (statusAddEducation.value == 3) {
        path = 'diplomath'
        body = {
          user_diploma_th_name: nameEducation.value,
        }
      } else if (statusAddEducation.value == 4) {
        path = 'diplomaen'
        body = {
          user_diploma_en_name: nameEducation.value,
        }
      }

      insertEducation(body, path).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update', res.message)
        dialogAddEducation.value = false
        processLoading.value = false
      })
    }

    const editEducation = (id, name, status) => {
      statusAddOrEdit.value = 2
      idEducation.value = id
      nameEducation.value = name
      statusEdit.value = status
      dialogAddEducation.value = true
    }

    const updateEducationData = () => {
      processLoading.value = true
      let body = {}
      let path = ''
      if (statusEdit.value == 1) {
        path = 'studyth'
        body = {
          user_study_th_id: idEducation.value,
          user_study_th_name: nameEducation.value,
        }
      } else if (statusEdit.value == 2) {
        path = 'studyen'
        body = {
          user_study_en_id: idEducation.value,
          user_study_en_name: nameEducation.value,
        }
      } else if (statusEdit.value == 3) {
        path = 'diplomath'
        body = {
          user_diploma_th_id: idEducation.value,
          user_diploma_th_name: nameEducation.value,
        }
      } else if (statusEdit.value == 4) {
        path = 'diplomaen'
        body = {
          user_diploma_en_id: idEducation.value,
          user_diploma_en_name: nameEducation.value,
        }
      }
      updateEducation(body, path).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update', res.message)
        dialogAddEducation.value = false
        processLoading.value = false
      })
    }

    const removeEducation = (id, status) => {
      let path1 = ''
      processLoading.value = true
      if (status == 1) {
        path1 = 'studyth'
      } else if (status == 2) {
        path1 = 'studyen'
      } else if (status == 3) {
        path1 = 'diplomath'
      } else if (status == 4) {
        path1 = 'diplomaen'
      }
      deleteEducation(id, path1).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update', res.message)
        dialogAddEducation.value = false
        processLoading.value = false
      })
    }

    props.accountData.specialized_sub.forEach(item => {
      specialized_sub_id.value.push(item.specialized_sub_id)
    })
    props.accountData.user_language.forEach(item => {
      user_language_id.value.push(item.user_language_id)
    })
    specializedsub().then(res => {
      specializedsubs.value = res
    })
    specialized().then(res => {
      specializeds.value = res
    })
    userLanguage().then(res => {
      languages.value = res
    })

    return {
      nameEducation,
      educationThList,
      educationEnList,
      certThList,
      certEnList,
      loadingUpdate,
      specializedsubs,
      specialized_sub_id,
      dialogAddEducation,
      specializeds,
      specialized_id,
      languages,
      user_language_id,
      title,
      statusAddEducation,
      processLoading,
      statusAddOrEdit,
      editBasicDoctorData,
      removeEducation,
      updateEducationData,
      addEduction,
      addEductionData,
      editEducation,
      icons: {
        mdiBriefcaseOutline,
        mdiPlus,
        mdiCircle,
        mdiSchool,
        mdiPencilOutline,
        mdiDeleteOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: 4rem;
}
</style>
