<template>
  <div>
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-card-text class="d-flex">
        <v-avatar
          rounded
          size="120"
          class="me-6"
        >
          <v-img
            contain
            :src="accountDataLocale.user_image"
          ></v-img>
        </v-avatar>
        <!-- upload photo -->
        <div>
          <v-btn
            color="primary"
            class="me-3 mt-5"
            :loading="loadingImage"
            :disabled="loadingImage"
            @click="$refs.refInputEl.click()"
          >
            <v-icon class="d-sm-none">
              {{ icons.mdiCloudUploadOutline }}
            </v-icon>
            <span class="d-none d-sm-block">{{ $t('upload_pictures') }}</span>
          </v-btn>
          <v-btn
            v-if="accountDataLocale.google_status_id == '0'"
            color="error"
            outlined
            class="me-3 mt-5"
            @click="isShowAlert = true"
          >
            <v-icon class="d-sm-none">
              {{ icons.mdiGooglePlus }}
            </v-icon>
            <span class="d-none d-sm-block">{{ $t('connect_google_plus') }}</span>
          </v-btn>
          <v-btn
            v-else
            color="error"
            outlined
            :loading="loadingGoogle"
            :disabled="loadingGoogle"
            class="me-3 mt-5"
            @click="signoutGoogle"
          >
            <v-icon class="d-sm-none">
              {{ icons.mdiGooglePlus }}
            </v-icon>
            <span class="d-none d-sm-block"> {{ $t('log_out') }}</span>
          </v-btn>
          <input
            ref="refInputEl"
            type="file"
            accept=".jpeg,.png,.jpg,GIF"
            :hidden="true"
            @change="previewFiles"
          />

          <p class="text-sm mt-5">
            {{ $t('function_features_5') }}
          </p>
        </div>
      </v-card-text>

      <v-card-text>
        <v-form class="multi-col-validation mt-6">
          <v-row>
            <v-col
              md="6"
              lg="4"
              cols="12"
            >
              <v-text-field
                v-model="accountDataLocale.username"
                label="Username"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col
              md="6"
              lg="4"
              cols="12"
            >
              <v-text-field
                v-model="accountDataLocale.user_license"
                :label="$t('license_number')"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              md="6"
              lg="4"
              cols="12"
            >
              <v-text-field
                v-model="accountDataLocale.user_fullname"
                :label="$t('firstname_lastname')"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              md="6"
              lg="4"
              cols="12"
            >
              <v-select
                v-model="accountDataLocale.user_gender"
                :label="$t('gender')"
                :items="genders"
                :item-text="$i18n.locale"
                item-value="value"
                dense
                outlined
              ></v-select>
            </v-col>
            <v-col
              md="6"
              lg="4"
              cols="12"
            >
              <v-text-field
                v-model="accountDataLocale.user_tel"
                disabled
                :label="$t('tel')"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              lg="4"
              md="6"
            >
              <v-text-field
                v-model="accountDataLocale.user_email"
                label="E-mail"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              lg="4"
              md="6"
            >
              <v-text-field
                v-model="accountDataLocale.user_gmail"
                :label="$t('connect_google_calendar')"
                dense
                disabled
                outlined
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              lg="4"
              md="6"
            >
              <v-text-field
                v-model="accountDataLocale.user_address"
                :label="$t('address')"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              lg="4"
              md="6"
            >
              <v-text-field
                v-model="accountDataLocale.user_comment"
                :label="$t('adjunct')"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-btn
                :loading="loadingupdate"
                :disabled="loadingupdate"
                color="primary"
                class="me-3 mt-4"
                @click="editProfile"
              >
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="isShowAlert"
      persistent
      max-width="450"
    >
      <v-card>
        <v-card-text>
          <p class="font-weight-semibold text-lg align-center">
            <v-icon color="warning">
              {{ icons.mdiAlertOutline }}
            </v-icon> {{ $t('alertCarlendar') }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :href="accountDataLocale.url_google"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="isShowAlert = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline, mdiGooglePlus } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import profile from '@/api/profile/profile'
import store from '@/store'

export default {
  props: {
    accountData: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, context) {
    const accountDataLocale = ref({})
    const statusUpdateProfile = ref(false)
    const loadingupdate = ref(false)
    const loadingImage = ref(false)
    const loadingGoogle = ref(false)
    const isShowAlert = ref(false)
    const user_image = ref('')
    const genders = ref([
      { value: 'ชาย', th: 'ชาย', en: 'Male' },
      { value: 'หญิง', th: 'หญิง', en: 'Female' },
    ])
    watch(
      () => props.loading,
      () => {
        accountDataLocale.value = props.accountData
      },
    )
    const resetForm = () => {
      accountDataLocale.value = JSON.parse(JSON.stringify(props.accountData))
    }
    const { updateProfile, uploadProfileImage, logoutGoogle } = profile

    const signoutGoogle = () => {
      loadingGoogle.value = true
      logoutGoogle().then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loadingGoogle.value = false
        context.emit('update', res.message)
      })
    }

    const previewFiles = event => {
      loadingImage.value = true
      user_image.value = event.target.files[event.target.files.length - 1]
      accountDataLocale.value.user_image = URL.createObjectURL(event.target.files[event.target.files.length - 1])
      updateImageProfile()
    }
    const updateImageProfile = () => {
      const formData = new FormData()
      formData.append('user_image', user_image.value)
      uploadProfileImage(formData).then(res => {
        store.commit('app/ALERT', {
          message: res.response ? 'upload_completed' : 'unsuccessful',
          color: res.response ? 'success' : 'error',
        })
        context.emit('update', res.message)
        loadingImage.value = false
      })
    }
    const editProfile = () => {
      loadingupdate.value = true
      updateProfile(accountDataLocale.value).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loadingupdate.value = false
        context.emit('update', res.message)
      })
    }

    return {
      accountDataLocale,
      statusUpdateProfile,
      genders,
      loadingupdate,
      loadingImage,
      loadingGoogle,
      isShowAlert,
      resetForm,
      editProfile,
      previewFiles,
      signoutGoogle,
      icons: {
        mdiGooglePlus,
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
    }
  },
}
</script>
