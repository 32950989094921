<template>
  <div>
    <v-card
      flat
      class="mt-5"
    >
      <v-form>
        <div class="px-3">
          <v-card-text class="pt-5">
            <v-row>
              <v-col
                cols="12"
                lg="8"
              >
                <!-- current password -->
                <v-text-field
                  v-model="passworConfirm"
                  :type="isCurrentPasswordVisible ? 'text' : 'password'"
                  :append-icon="isCurrentPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                  :label="$t('set_password_confirm')"
                  :placeholder="$t('inputPasswordYouWant')"
                  outlined
                  dense
                  @click:append="isCurrentPasswordVisible = !isCurrentPasswordVisible"
                ></v-text-field>

                <!-- new password -->
                <v-text-field
                  v-model="currentPassword"
                  :type="isNewPasswordVisible ? 'text' : 'password'"
                  :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                  :label="$t('inputUsernamePasswordForConfirm')"
                  outlined
                  dense
                  :placeholder="$t('inputPasswordLogin')"
                  persistent-hint
                  @click:append="isNewPasswordVisible = !isNewPasswordVisible"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                lg="4"
                class="d-none d-lg-flex justify-center position-relative"
              >
                <v-img
                  contain
                  max-width="150"
                  src="@/assets/images/3d-characters/profile/lock.png"
                  class="security-character"
                ></v-img>
              </v-col>
            </v-row>
          </v-card-text>
        </div>

        <div class="pa-3">
          <!-- action buttons -->
          <v-card-text>
            <v-btn
              :loading="loading"
              :disabled="loading"
              color="primary"
              class="me-3 mt-3"
              @click="updateConfrimPassword"
            >
              {{ $t('save') }}
            </v-btn>
          </v-card-text>
        </div>
      </v-form>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOffOutline, mdiEyeOutline, mdiKeyOutline, mdiLockOpenOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import profile from '@/api/profile/profile'
import store from '@/store'

export default {
  setup(props, { emit }) {
    const isCurrentPasswordVisible = ref(false)
    const isNewPasswordVisible = ref(false)
    const isCPasswordVisible = ref(false)
    const currentPassword = ref('')
    const passworConfirm = ref('')

    const loading = ref(false)
    const { changeConfirmPassword } = profile
    const clearData = () => {
      currentPassword.value = ''
      passworConfirm.value = ''
      loading.value = false
    }
    const updateConfrimPassword = () => {
      loading.value = true
      const body = {
        password: currentPassword.value,
        user_key: passworConfirm.value,
      }
      changeConfirmPassword(body).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        clearData()
        emit('update', res.message)
      })
    }

    return {
      isCurrentPasswordVisible,
      isNewPasswordVisible,
      currentPassword,
      isCPasswordVisible,
      passworConfirm,
      loading,

      updateConfrimPassword,
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: -4rem;
}
</style>
